export const form = {
	id:null,
	title:undefined,
	content:undefined,
	status:undefined,
	isPublic:undefined,
	isRating:undefined,
	imgUrls:[]
}
export const rules = {
	title:[{required:true,message:'请输入标题',trigger:'blur'}],
	content:[{required:true,message:'请输入内容',trigger:'blur'}],
	status:[{required:true,message:'请选择',trigger:'change'}],
	isPublic:[{required:true,message:'请选择',trigger:'change'}],
	isRating:[{required:true,message:'请选择',trigger:'change'}],
}
export const options = {
	isTrue:[
		{ id:1, name:'是' },
		{ id:0, name:'否' },
	],
	status: [
		{ id:1, name:'启用中' },
		{ id:2, name:'禁用中' },
	]
}