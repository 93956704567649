export const formItem = [
	{
		type: 'input',
		label:'话题标题',
		prop:'title',
		placeholder:'请输入标题'
	},
	{
		type: 'select',
		label:'状态',
		prop:'status',
		option:[{ id:1,name:'启用中'},{ id:2,name:'禁用中'}],
		placeholder:'请选择状态'
	},
	{
		type: 'select',
		label:'是否公开',
		prop:'isPublic',
		placeholder:'请选择',
		option:[{ id:1,name:'是'},{ id:0,name:'否'}]
	},
	{
		type: 'select',
		label:'是否评论',
		prop:'isRating',
		placeholder:'请选择',
		option:[{ id:1,name:'是'},{ id:0,name:'否'}]
	},
]
export const columns = [
	{
		title: "话题标题",
		dataIndex: "title",
		width: 184,
	},
	{
		title: "内容",
		dataIndex: "content",
		width: 222,
	},
	{
		title: "图片",
		dataIndex: "imgpic",
		width: 106,
		scopedSlots: { customRender: "imgpic"}
	},
	{
		title: "状态",
		dataIndex: "status",
		width: "10%",
		customRender: function (status) {
			switch (status) {
			case 1:
				return '启用中'
			case 2:
				return '禁用中'
			}
		}
	},
	{
		title: "是否公开",
		dataIndex: "isPublic",
		width: "9%",
		customRender: function (isPublic) {
			switch (isPublic) {
			case 1:
				return '是'
			case 0:
				return '否'
			}
		}
	},
	{
		title: "是否可以评论",
		dataIndex: "isRating",
		width: "10%",
		customRender: function (isRating) {
			switch (isRating) {
			case 1:
				return '是'
			case 0:
				return '否'
			}
		}
	},
	{
		title: "用户类型",
		dataIndex: "userType",
		width: "10%",
		customRender: function (userType) {
			switch (userType) {
			case 1:
				return '住户'
			case 2:
				return '物业'
			}
		}
	},
	{
		title: "发布人",
		dataIndex: "createName",
		width: "8%",
	},
	{
		title: "发布时间",
		dataIndex: "createDate",
		width: "12%",
	},
	{
		title: "是否开启租赁",
		dataIndex: "isEnableLease",
		scopedSlots: { customRender: "switch" },
	},
	{
		title: "操作",
		dataIndex: "action",
		key: "action",
		width: "180",
		fixed: "right",
		scopedSlots: { customRender: "action" },
	},
]
export const pagination = {
	current: 1,
	total: 0,
	pageSize: 10,
	showTotal: (total) => `共 ${total} 条`,
	showSizeChanger: true,
	showQuickJumper: true,
}