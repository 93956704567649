import httpService from "@/request"

// 话题列表
export function topicList(params) {
	return httpService({
		url: `/user/topic/list`,
		method: 'get',
		params: params,
	})
}
// 话题新增
export function topicInsert(params) {
	return httpService({
		url: `/user/topic/insert`,
		method: 'post',
		data: params,
	})
}
// 话题信息
export function topicInfo(params) {
	return httpService({
		url: `/user/topic/findById`,
		method: 'get',
		params: params,
	})
}
// 话题修改
export function topicUpdate(params) {
	return httpService({
		url: `/user/topic/update`,
		method: 'post',
		data: params,
	})
}
// 话题删除
export function topicDelete(params) {
	return httpService({
		url: `/user/topic/delete`,
		method: 'post',
		data: params,
	})
}
