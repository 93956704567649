<template>
  <div>
    <a-drawer
      :title="title"
      :width="720"
      :visible="show"
      :body-style="{ paddingBottom: '80px' }"
      @close="addClose"
    >
      <div class="drawer-content">
        基本信息
        <a-divider></a-divider>
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          layout="vertical"
        >
          <a-form-model-item prop="title" label="标题">
            <a-input
              v-model="form.title"
              placeholder="请输入标题"
              style="width: 60%"
            ></a-input>
          </a-form-model-item>
          <a-form-model-item prop="content" label="内容">
            <a-input
              v-model="form.content"
              placeholder="请输入标题"
              style="width: 60%"
            ></a-input>
          </a-form-model-item>
          <a-form-model-item prop="status" label="状态">
            <a-select
              v-model="form.status"
              placeholder="请选择"
              style="width: 60%"
            >
              <a-select-option
                v-for="item in options.status"
                :key="item.id"
                :value="item.id"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="isPublic" label="是否公开">
            <a-select
              v-model="form.isPublic"
              placeholder="请选择"
              style="width: 60%"
            >
              <a-select-option
                v-for="item in options.isTrue"
                :key="item.id"
                :value="item.id"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="isRating" label="是否可以评论">
            <a-select
              v-model="form.isRating"
              placeholder="请选择"
              style="width: 60%"
            >
              <a-select-option
                v-for="item in options.isTrue"
                :key="item.id"
                :value="item.id"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
          话题图片
          <commonUpload :fileList='fileList' @handleChange="handleChange" />
        </a-form-model>
      </div>
      <div class="drawer-footer">
        <a-button :style="{ marginRight: '8px' }" @click="addClose">
          关闭
        </a-button>
        <a-button type="primary" @click="submit"> 提交 </a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { form, rules, options } from "./form.js";
import { topicInsert,topicUpdate, topicInfo } from '@/api/operation/dynamic/topic'
export default {
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		editId:Number
	},
	data() {
		return {
			title: "新增话题",
			form,
			rules,
			options,
			fileList:[]
		};
	},
	methods: {
		addClose() {
			this.$refs.ruleForm.resetFields();
			this.fileList = []
			this.form.imgUrls = []
			this.$emit('addClose');
		},
		success() {
			this.$emit('success');
			this.addClose()
		},
		submit() {
			// console.log(this.form)
			this.$refs.ruleForm.validate(async (valid) => {
				if (valid) {
					if(this.editId === null) {
						let res  = await topicInsert(this.form)
						if(res.code===200){
							this.$message.success(res.msg)
							this.success()
						}else{
							this.$message.error(res.msg)
						}
					}else{
						let res  = await topicUpdate(this.form)
						if(res.code===200){
							this.$message.success(res.msg)
							this.success()
						}else{
							this.$message.error(res.msg)
						}
					}
				};
			});
		},
		// handleChange(imgDataList) {
		// 	this.fileList = imgDataList
		// 	let arr = []
		// 	imgDataList.forEach(ele => {
		// 		arr.push(ele.response.data)
		// 	})
		// 	this.form.imgUrls = arr
		// },
		handleChange(data) {
			this.fileList = data;
			this.form.imgUrls = [];
			data.forEach(ele => {
				if(ele.status == 'done') {
					this.form.imgUrls.push(ele.response.data)
				}
			})
		},
	},
	watch: {
		editId:{
			handler(val){
				if(val!==null){
					this.title = '修改话题'
					this.form.id = val
					this.form.imgUrls = [];
					topicInfo({topicId:val}).then(res=>{
						let data = res.data
						this.form.title = data.title
						this.form.content = data.content
						this.form.status = data.status
						this.form.isPublic = data.isPublic
						this.form.isRating = data.isRating
						if(data.imgList.length>0){
							const pic = []
							this.form.imgUrls.push(data.imgList[0].url)
							for(let item of data.imgList){
								let obj = {
									name:item.url.split('_')[0] +'.'+ item.url.split('.')[1],
									url: this.$ImgUrl(item.url),
									uid:item.url.split('_')[1],
									status:'done',
									thumbUrl: this.$ImgUrl(item.url),
								}
								pic.push(obj)
							}
							this.fileList = pic
						}
					})
				}else{
					this.title = '新增话题'
				}
			},
			immediate: true
		}
	}
};
</script>

<style></style>
